import React from "react";
import styles from "./footer.module.scss";
// import Image from "next/image";

const Footer = () => {
  return (
    <div className={`footer ${styles.footer}`}>
      <div className="container">
        <div className={styles.contacts}>
          <h2>НАШі контакти</h2>
          <div className={styles.social}>
            <a href="#">
              <img src="/assets/icons/viber.svg" alt="viber" />
            </a>
            <a href="#">
              <img src="/assets/icons/whatsapp.svg" alt="whatsapp" />
            </a>
            <a href="#">
              <img src="/assets/icons/telegram.svg" alt="telegram" />
            </a>
            <a className={styles.numberPhone} href="tel:+380680898961">
              +380 (68) 089 8961
            </a>
          </div>
          <a className={styles.email} href="mailto:bus.ukreurope@gmail.com">
            <span>E-mail: </span>
            bus.ukreurope@gmail.com
          </a>
          <div className={styles.address}>
            <span>Aдреса: </span>м. Київ, вулиця Володимирська 47(метро Золоті
            ворота)
          </div>
        </div>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1564.1060284491944!2d30.512716300801017!3d50.4478769834434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4ce59d20cff2f%3A0xd14ea1222131262!2z0LLRg9C70LjRhtGPINCS0L7Qu9C-0LTQuNC80LjRgNGB0YzQutCwLCA0Nywg0JrQuNGX0LIsIDAxMDAx!5e0!3m2!1suk!2sua!4v1699891886283!5m2!1suk!2sua"
        width="600"
        height="450"
        // style="border:0;"
        allowFullScreen=""
        loading="lazy"
        // referrerpolicy="no-referrer-when-downgrade"
        className={styles.mapIframe}
      ></iframe>
    </div>
  );
};

export default Footer;
