"use client";
import React, { useEffect, useRef, useState } from "react";
import styles from "./menu.module.scss";
import gsap from "gsap";

const menuItems = [
  { title: "Маршрути", link: "#travelRoutes" },
  { title: "Про нас", link: "#about" },
  { title: "Автопарк", link: "#carpark" },
  { title: "Відгуки", link: "#reviews" },
];

const Menu = ({ setIsMenuOpen }) => {
  const menuRef = useRef(null);
  const [leaveAnimation, setLeaveAnimation] = useState(false);

  useEffect(() => {
    gsap.fromTo(
      menuRef.current,
      {
        duration: 0.5,
        x: 300,
        ease: "elastic(0.1, 0.75)",
      },
      {
        duration: 0.5,
        x: 0,
        ease: "elastic(0.1, 0.75)",
      }
    );
  }, []);

  useEffect(() => {
    if (leaveAnimation) {
      gsap.fromTo(
        menuRef.current,
        {
          duration: 0.5,
          x: 0,
          opacity: 1,
          ease: "elastic(1, 0.75)",
        },
        {
          duration: 0.5,
          x: "100%",
          opacity: 0,
          ease: "elastic(1, 0.75)",
        }
      );
    }
  }, [leaveAnimation]);

  // const handleClickOutside = (e) => {
  //   if (!e.target.contains(menuRef.current)) {
  //     setLeaveAnimation(true);
  //     setTimeout(() => {
  //       setIsMenuOpen(false);
  //     }, 500);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);

  return (
    <div
      className={`menu 
      ${styles.menu} ${leaveAnimation ? "leaveAnimation" : ""}`}
      ref={menuRef}
    >
      <div className={styles.close}>
        <img
          src="/assets/icons/close.svg"
          alt="close"
          onClick={() => {
            setLeaveAnimation(true);
            setTimeout(() => {
              setIsMenuOpen(false);
            }, 500);
          }}
        />
      </div>
      <nav>
        {menuItems.map((item, index) => (
          <a href={item.link} key={index} onClick={() => setIsMenuOpen(false)}>
            {item.title}
          </a>
        ))}
      </nav>
      <div className={styles.contacts}>
        <strong>контакти</strong>
        <a className={styles.numberPhone} href="tel:+380680898961">
          <img src="/assets/icons/whitePhone.svg" alt="phone" />
          +380 (68) 089 8961
        </a>
        <div className={styles.socials}>
          <a href="viber://chat?number=%2B380680898961">
            <img src="/assets/icons/viber.svg" alt="viber" />
          </a>
          <a href="https://wa.me/380680898961">
            <img src="/assets/icons/whatsapp.svg" alt="whatsapp" />
          </a>
          <a href="https://t.me/Artem_P27">
            <img src="/assets/icons/telegram.svg" alt="telegram" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Menu;
