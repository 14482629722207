"use client";

import React, { useEffect, useState } from "react";
import styles from "./answers.module.scss";
import gsap from "gsap";
import { BsChevronDown } from "react-icons/bs";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const data = [
  {
    question: "Чи є можливість забрати/довезти по адресі?",
    answer:
      "Так, в Європі підʼїжджаємо по адресі, в Україні це може бути авто/жд вокзал або так само по адресі.",
  },
  {
    question: "Чи потрібно вносити передплату?",
    answer:
      "Ні, передплат не беремо. Оплата поїздки здійснюється на місці водію(при посадці або по домовленості по приїзду в кінці поїздки)",
  },
  {
    question: "Які правила стосовно багажу?",
    answer:
      "За правилами у вартість поїздки входить 2 валізи та сумка, але може бути більше з невеликою доплатою.",
  },
  {
    question: "Чи є знижки для дітей та сім’ї чи компанії?",
    answer:
      "Так, для дітей, сімʼї та компаній з 2 та більше людей робимо знижки.",
  },
  {
    question: "З яких міст в Україні виїжджаєте?",
    answer:
      "Маємо маршрути по всій Україні, окрім тимчасово окупованих територій. Детальніше уточнюйте по телефону.",
  },
  {
    question: "По яким дням здійснюєте рейси?",
    answer:
      "Більшість наших маршрутів зi щоденними виїздами, але графік може змінюватися, уточнюйте по телефону завчасно.",
  },
  {
    question: "В якій валюті можна провести розрахунок?",
    answer: "Як вам зручно. Євро, долари, гривні. Готівка, карта. ",
  },
  {
    question: "Де знаходиться ваш офіс?",
    answer: "м. Київ, вулиця Володимирська 47(метро Золоті ворота)",
  },
  {
    question: "У вас прямі рейси, без пересадок?",
    answer: "Так, всі наші рейси прямі, без пересадок. ",
  },
  {
    question: "Чи можна прилягти в дорозі?",
    answer:
      "Так, звісно. Наші авто для вашого комфорту обладнані зручними розкладними сидіннями.",
  },
];

const Answers = () => {
  const [active, setActive] = useState(null);
  const [prevActive, setPrevActive] = useState(null);

  const prevActiveHandler = (index) => {
    setPrevActive(index);
  };

  useEffect(() => {
    const groups = gsap.utils.toArray(".accordionGroup");

    groups.forEach((group, index) => {
      const menu = group.querySelector(".accordionMenu");
      const content = group.querySelector(".accordionContent");

      gsap.set(content, { height: index === active ? "auto" : 0 });

      const tween = gsap.fromTo(
        content,
        {
          height: index === prevActive ? "auto" : 0,
          duration: 0.5,
          ease: "power1.inOut",
          marginTop: index === prevActive ? 20 : 0,
        },
        {
          height: index === active ? "auto" : 0,
          duration: 0.5,
          ease: "power1.inOut",
          marginTop: index === active ? 20 : 0,
        }
      );

      if (index === active) {
        tween.play();
      } else {
        tween.reverse();
      }
    });
    let prevActiveGroup = groups[prevActive];

    if (prevActiveGroup) {
      const prevActiveContent =
        prevActiveGroup.querySelector(".accordionContent");
      // gsap.set(prevActiveContent, { height: "auto" });
      gsap.to(
        prevActiveContent,

        {
          height: 0,
          duration: 0.5,
          ease: "power1.inOut",
          marginTop: 0,
        }
      );
    }
  }, [active]);
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.fromTo(
      ".accordionGroup",
      {
        duration: 0.5,
        opacity: 0,
        y: -100,
      },
      {
        duration: 0.5,
        opacity: 1,
        y: 0,
        stagger: 0.2,
        ease: "elastic(1, 0.75)",
        scrollTrigger: {
          trigger: ".accordionGroup",
          start: "top 80%",
          end: "top 50%",
          toggleActions: "play none none reverse",
        },
      }
    );
  }, []);

  return (
    <div className={`answers ${styles.answers}`}>
      <div className="container">
        <strong>відповіді на запитання</strong>
        <h2>Нас часто запитують</h2>

        <div className={`accordion ${styles.accordion}`}>
          {data.map((item, index) => (
            <div
              key={index}
              className={` accordionGroup ${
                active === index ? "active" : "close"
              } 
              ${prevActive === index ? "prevActive" : "prevClose"}`}
              onClick={() => {
                // setActive(null);
                setActive(index);
                prevActiveHandler(active);
              }}
            >
              <div className="accordionMenu">
                <div className={styles.number}>
                  {index < 9 ? `0${index + 1}` : index + 1}
                </div>
                <div>{item.question}</div>
                <div className={styles.arrow}>
                  <BsChevronDown />
                </div>
              </div>
              <div className="accordionContent">{item.answer}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Answers;
