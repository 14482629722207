import React from "react";
import { IoMenu } from "react-icons/io5";
// import Image from "next/image";

import styles from "./header.module.scss";
// import Menu from "./Menu";

const Header = ({ setIsMenuOpen }) => {
  return (
    <div className={` header ${styles.header}`}>
      <div className="wrapper">
        <div className="container">
          <img
            src="/assets/icons/bus.svg"
            alt="logo"
            // width={100}
            // height={100}
            className={styles.logo}
          />
          <a className={styles.numberPhone} href="tel:+380680898961">
            <img
              src="/assets/icons/phone.svg"
              alt="phone"
              // width={20}
              // height={20}
            />
            +380 (68) 089 8961
          </a>
          <div className={styles.menu}>
            <span>Menu</span>
            <div
              className={styles.menuIcon}
              onClick={() => setIsMenuOpen(true)}
            >
              <IoMenu />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
