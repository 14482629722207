import React, {useEffect} from "react";
import styles from "./carPark.module.scss";
// import Image from "next/image";
import gsap from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const images = [
  {imageURL: "/assets/carPark7.png"},
  {imageURL: "/assets/carPark8.png"},
  {imageURL: "/assets/carPark1.png"},
  {imageURL: "/assets/carPark2.png"},
  {imageURL: "/assets/carPark4.png"},
  {imageURL: "/assets/carPark9.png"},
  {imageURL: "/assets/carPark10.png"},
  {imageURL: "/assets/carPark5.png"},
  {imageURL: "/assets/carPark6.png"},
];

const CarPark = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.fromTo(
      ".car",
      {
        opacity: 0,
        x: 100,
        stagger: 0.2,
      },
      {
        opacity: 1,
        x: 0,
        stagger: 0.2,
        duration: 1,
        scrollTrigger: {
          trigger: ".car",
          start: "top 80%",
          end: "top 50%",
          toggleActions: "play none none reverse",
        },
        ease: "elastic(1, 0.75)",
      }
    );
  }, []);
  return (
    <div className={styles.carPark} id="carpark">
      <div className="container">
        <strong>Автопарк</strong>
        <h2>
          Наші <span>комфортабельні</span> мікроавтобуси
        </h2>
        <p className={styles.description}>
          АВТОПАРК НАШОЇ КОМПАНІЇ СКЛАДАЄТЬСЯ З КОМФОРТАБЕЛЬНИХ МІКРОАВТОБУСІВ
          MERSEDES SPRINTER, ОСНАЩЕНИХ КОМФОРТНИМИ РОЗКЛАДНИМИ СИДІННЯМИ З
          ДОСТАТНЬОЮ ВІДСТАННЮ ДЛЯ НІГ ДЛЯ ПОВНОЦІННОГО ВІДПОЧИНКУ ПІД ЧАС
          ДОРОГИ, ТЕЛЕВІЗОРАМИ, WI-FI, USB ЗАРЯДКАМИ ДЛЯ ТЕЛЕФОНА,
          КОНДИЦІОНЕРАМИ.
        </p>

        {/* <div className={styles.images}>
          {images.map((image, index) => (
            <img key={index} alt="Bus" src={image.imageURL} className="car" />
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default CarPark;
