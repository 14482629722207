import React, { useEffect, useRef } from "react";
import styles from "./banner.module.scss";
// import Image from "next/image";
import Button from "../../../ui-elements/Button";
import { BsChevronRight } from "react-icons/bs";
import gsap from "gsap";

const Banner = ({ setIsShowFindOutThePricePopup }) => {
  const bannerRef = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline();
    const animRef = gsap.utils.toArray(".anim");
    tl.fromTo(
      bannerRef.current,
      {
        duration: 0.7,
        opacity: 0,
        // y: -300,
        scale: 0.5,
        transformOrigin: "right top",
        ease: "power4.out",
      },
      {
        duration: 0.7,
        opacity: 1,
        // y: 0,
        scale: 1,
        transformOrigin: "right top",
        ease: "power4.out",
      }
    );
    tl.fromTo(
      animRef,
      {
        duration: 0.5,
        opacity: 0,
        x: -100,
        stagger: 0.2,
        ease: "elastic(1, 0.75)",
      },
      {
        duration: 0.5,
        opacity: 1,
        x: 0,
        stagger: 0.2,
        ease: "elastic(1, 0.75)",
      }
    );
  }, []);
  return (
    <div className={` banner ${styles.banner}`} ref={bannerRef}>
      <img
        className={styles.bannerImg}
        src="/assets/bannerBus.jpg"
        alt="banner"
      />
      <div className="container">
        <div className={styles.mainInfo}>
          <h1 className="anim">Пасажирські перевезення</h1>
          <p className={` anim ${styles.subtitle}`}>
            до країн Європи та України
          </p>
          <div className={`anim ${styles.buttons}`}>
            <Button>
              <a href="tel:+380680898961">+380 (68) 089 8961</a>
            </Button>
            <Button
              dark={true}
              click={() => setIsShowFindOutThePricePopup(true)}
            >
              Дізнатися ціну
              <BsChevronRight />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
