import React, { useState } from "react";
// import styles from "./styles/globals.sass";
import Header from "./components/Header";
import MainContent from "./components/MainContent";
import Footer from "./components/Footer";
import Menu from "./components/Header/Menu";
import "./styles/globals.scss";
import styles from "./styles/app.module.scss";

export default function Home() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // console.log("isMenuOpen", isMenuOpen)

  return (
    <div className={`app wrapper ${styles.wrapper}`}>
      <Header setIsMenuOpen={setIsMenuOpen} />
      {isMenuOpen && <Menu setIsMenuOpen={setIsMenuOpen} />}
      <MainContent />
      <Footer />
    </div>
  );
}
