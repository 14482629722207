"use client";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, {useState} from "react";
import Slider from "react-slick";
import styles from "./reviews.module.scss";
import {BsChevronLeft, BsChevronRight} from "react-icons/bs";

const reviews = [
  {imageURL: "/assets/r1.png"},
  {imageURL: "/assets/r2.png"},
  {imageURL: "/assets/r3.png"},
  {imageURL: "/assets/r4.png"},
  {imageURL: "/assets/r5.png"},
  {imageURL: "/assets/r6.png"},
  {imageURL: "/assets/r7.png"},
  {imageURL: "/assets/r8.png"},
];

function SampleNextArrow(props) {
  const {onClick} = props;
  return (
    <div className="nextArrow" onClick={onClick}>
      <button>
        <BsChevronRight />
      </button>
    </div>
  );
}

function SamplePrevArrow(props) {
  const {onClick} = props;
  return (
    <div className="prevArrow" onClick={onClick}>
      <button>
        <BsChevronLeft />
      </button>
    </div>
  );
}
const Reviews = () => {
  const [initialSlide, setInitialSlide] = useState(0);
  const initialSlideHandler = (index) => {
    setInitialSlide(index);
  };

  const [afterChange, setAfterChange] = useState(0);
  const afterChangeHandler = (index) => {
    setAfterChange(index);
  };

  const settings = {
    dots: false,
    infinite: true,
    // speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    touchMove: true,
    // initialSlide: initialSlide,
    // afterChange: afterChange,
    beforeChange: (current, next) => initialSlideHandler(next),
    afterChange: (current) => afterChangeHandler(current),

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className={styles.reviewsWrapper}>
      <div className={styles.reviews} id="reviews">
        <div className="container">
          <h2>
            <span>ВІДГУКИ</span> НАШИХ ПАСАЖИРІВ
          </h2>
          <Slider {...settings}>
            {reviews.map((review, index) => (
              <img key={index} src={review.imageURL} alt="review" />
            ))}
          </Slider>
          <div className="sliderCounter">
            {initialSlide + 1} / {reviews.length}
          </div>
        </div>
      </div>
      <p className="container ">
        Це тільки маленька частина відгуків про нашу роботу, з більше ніж 5000
        відгуків ви можете ознайомитися у нашій групі у Viber, WhatsApp та
        Instagram, всі посилання надасть водій або менеджер
      </p>
    </div>
  );
};

export default Reviews;
