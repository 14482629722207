import styles from "./button.module.scss";

const Button = ({ children, dark, click }) => {
  return (
    <div
      className={`button ${styles.button} ${dark ? styles.darkBg : ""}`}
      onClick={click}
    >
      {children}
    </div>
  );
};

export default Button;
