import React, { useEffect, useRef } from "react";
import styles from "./reservation.module.scss";
import Button from "../../../ui-elements/Button";
import { BsChevronDown } from "react-icons/bs";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

const Reservation = ({ setIsShowOrderRidePopup }) => {
  const reservationRef = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.fromTo(
      reservationRef.current,
      {
        duration: 0.6,
        opacity: 0,
        y: -200,
        // scale: 0.5,
      },
      {
        duration: 0.6,
        opacity: 1,
        y: 0,
        // scale: 1,
        scrollTrigger: {
          trigger: reservationRef.current,
          start: "top 70%",
          end: "bottom 80%",
          // toggleActions: "restart none none none",
        },
      }
    );
  }, []);

  return (
    <div className={styles.reservation} ref={reservationRef}>
      <div className="container">
        <div className={styles.content}>
          <div className={styles.leftColumn}>
            <strong>Трансфер</strong>
            <h2 className={`${styles.reservation__subtitle}`}>
              <span>забронювати</span> поїздку Україна - Європа
            </h2>
            <Button click={() => setIsShowOrderRidePopup(true)}>
              Забронювати поїздку <BsChevronDown />{" "}
            </Button>
          </div>
          <div className={styles.rightColumn}>
            <img src="/assets/bus2.png" alt="Bus" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reservation;
