import React, { useEffect, useRef, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import styles from "./findOutThePricePopup.module.scss";
import Button from "../../../ui-elements/Button";
import { BsChevronRight } from "react-icons/bs";
import { FaTelegramPlane, FaViber, FaWhatsapp } from "react-icons/fa";
import emailjs from "@emailjs/browser";
import gsap from "gsap";
import { useForm } from "react-hook-form";

const FindOutThePricePopup = ({
  setIsShowFindOutThePricePopup,
  setSuccess,
}) => {
  const popupRef = useRef(null);
  const [isSubmitPossible, setIsSubmitPossible] = useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    getValues,
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    if (isSubmitPossible) {
      // console.log(data, "data");
      setSuccess(true);

      const templateParams = {
        to_name: "Артем",
        from_name: data.name,
        phone: data.phone,
      };

      emailjs
        .send(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          templateParams,
          process.env.REACT_APP_PUBLIC_KEY
        )
        .then(
          function (response) {
            console.log("SUCCESS!", response.status, response.text);
            reset();
            setIsShowFindOutThePricePopup(false);
            setSuccess(false);
            localStorage.setItem("submissionTime", Date.now());
          },
          function (error) {
            console.log("FAILED...", error);
            setSuccess(false);
          }
        );
    }
  };

  // console.log(getValues(), "getValues");

  const error = (data) => {
    console.log(data);
  };

  if (!isSubmitPossible) {
    alert("Ви вже відправили заявку, будь ласка дочекайтесь дзвінка від нас)");
    setIsShowFindOutThePricePopup(false);
  }

  useEffect(() => {
    gsap.fromTo(
      popupRef.current,
      {
        duration: 0.7,
        opacity: 0,
        scale: 0.5,
        ease: "elastic(1, 0.75)",
      },
      {
        duration: 0.7,
        opacity: 1,
        scale: 1,
        ease: "elastic(1, 0.75)",
      }
    );
  }, []);

  useEffect(() => {
    const submissionTime = localStorage.getItem("submissionTime");
    if (submissionTime) {
      const twentyFourHours = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
      // const twentyFourHours = 30 * 1000; // 1 minute in milliseconds
      const currentTime = Date.now();
      const elapsedTime = currentTime - submissionTime;
      if (elapsedTime >= twentyFourHours) {
        setIsSubmitPossible(true);
      } else {
        setIsSubmitPossible(false);
      }
    }
  }, []);

  return (
    <div className={styles.findOutThePricePopup}>
      <form
        className={styles.findOutThePricePopup__content}
        ref={popupRef}
        onSubmit={handleSubmit(onSubmit, error)}
      >
        <div
          className={styles.close}
          onClick={() => setIsShowFindOutThePricePopup(false)}
        >
          <IoCloseSharp />
        </div>
        <h2>
          Дізнатися <span>ціну</span>
        </h2>
        <p className={styles.subtitle}>
          для того щоб дізнатися ціну поїздки зверніться по вказаному телефону
          або залиште ваш номер, ми вам зателефонуємо
        </p>
        <label>
          <img src="/assets/icons/users.svg" alt="phone" />
          <input
            type="text"
            placeholder="Ваше ім’я"
            {...register("name", {
              required: "Введіть ім'я",
              minLength: {
                value: 2,
                message: "Імʼя повинно бути не менше 2 символів",
              },
              maxLength: {
                value: 15,
                message: "Імʼя повинно бути не більше 15 символів",
              },
            })}
          />
        </label>
        {errors.name && (
          <p className="errorMess">{errors.name && errors.name.message}</p>
        )}
        <label>
          <img src="/assets/icons/whitePhone.svg" alt="phone" />
          <input
            type="text"
            placeholder="Номер телефону"
            {...register("phone", {
              required: "Введіть номер телефону",
              pattern: {
                value: /^\+?[0-9]+$/,
                message: "Номер телефону не повинент містити букви",
              },
              maxLength: {
                value: 20,
                message: "Номер телефону повинен бути не більше 20 символів",
              },
              minLength: {
                value: 10,
                message: "Номер телефону повинен бути не менше 10 символів",
              },
            })}
          />
        </label>
        {errors.phone && (
          <p className="errorMess">{errors.phone && errors.phone.message}</p>
        )}
        <button type="submit" disabled={!isValid}>
          <Button>
            Відправити <BsChevronRight />
          </Button>
        </button>
        <h3>
          наші <span>контакти</span>
        </h3>
        <a className={styles.tel} href="tel:+380680898961">
          +380 (68) 089 8961
        </a>
        <div className={styles.socials}>
          <a href="viber://chat?number=%2B380680898961">
            <FaViber />
          </a>
          <a href="https://wa.me/380680898961">
            <FaWhatsapp />
          </a>
          <a href="https://t.me/Artem_P27">
            <FaTelegramPlane />
          </a>
        </div>
      </form>
    </div>
  );
};

export default FindOutThePricePopup;
