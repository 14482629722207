"use client";
import React, { useEffect, useRef, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { HiUsers } from "react-icons/hi";
import { FaPhone } from "react-icons/fa6";
import { HiMapPin } from "react-icons/hi2";
import { FaCalendarCheck } from "react-icons/fa";
import gsap from "gsap";
import styles from "./orderRidePopup.module.scss";
import Button from "../../../ui-elements/Button";
import { BsChevronRight } from "react-icons/bs";
import emailjs from "@emailjs/browser";
import { useForm } from "react-hook-form";

const OrderRidePopup = ({ setIsShowOrderRidePopup, setSuccess }) => {
  const popupRef = useRef(null);
  const textareaRef = useRef(null);
  const dateInputRef = useRef(null);

  const [selectedDate, setSelectedDate] = useState("");

  const [isSubmitPossible, setIsSubmitPossible] = useState(true);

  const autoHeight = () => {
    textareaRef.current.style.height = "auto";
    textareaRef.current.style.height = textareaRef.current.scrollHeight + "px";
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    getValues,
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    if (isSubmitPossible) {
      setSuccess(true);

      const templateParams = {
        from_name: data.name,
        to_name: "Артем",
        date: data.date,
        from: data.from,
        to: data.to,
        phone: data.phone,
        message: data.additionalInfo,
      };

      emailjs
        .send(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID2,
          templateParams,
          process.env.REACT_APP_PUBLIC_KEY
        )
        .then(
          function (response) {
            console.log("SUCCESS!", response.status, response.text);
            reset();
            setIsShowOrderRidePopup(false);
            setSuccess(false);
            localStorage.setItem("submissionTime2", Date.now());
          },
          function (error) {
            console.log("FAILED...", error);
            setSuccess(false);
          }
        );
    }
  };

  const error = (data) => {
    console.log(data);
  };
  if (!isSubmitPossible) {
    alert("Ви вже відправили заявку, будь ласка дочекайтесь дзвінка від нас)");
    setIsShowOrderRidePopup(false);
  }

  useEffect(() => {
    gsap.fromTo(
      popupRef.current,
      {
        duration: 0.7,
        opacity: 0,
        scale: 0.5,
        ease: "elastic(1, 0.75)",
      },
      {
        duration: 0.7,
        opacity: 1,
        scale: 1,
        ease: "elastic(1, 0.75)",
      }
    );
  }, []);

  useEffect(() => {
    const submissionTime = localStorage.getItem("submissionTime2");
    if (submissionTime) {
      const twentyFourHours = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
      // const twentyFourHours = 30 * 1000; // 1 minute in milliseconds
      const currentTime = Date.now();
      const elapsedTime = currentTime - submissionTime;
      if (elapsedTime >= twentyFourHours) {
        setIsSubmitPossible(true);
      } else {
        setIsSubmitPossible(false);
      }
    }
  }, []);

  return (
    <div className={styles.orderRidePopup}>
      <form
        className={styles.orderRidePopup__content}
        ref={popupRef}
        onSubmit={handleSubmit(onSubmit, error)}
      >
        <div
          className={styles.close}
          onClick={() => setIsShowOrderRidePopup(false)}
        >
          <IoCloseSharp />
        </div>
        <strong>ЗАБРОНЮВАТИ ПОЇЗДКУ</strong>
        <h2>ЗАПОВНІТЬ ФОРМУ</h2>
        <label>
          <HiUsers />
          <input
            type="text"
            placeholder="Ваше ім’я"
            {...register("name", {
              required: "Введіть ім'я",
              minLength: {
                value: 2,
                message: "Імʼя повинно бути не менше 2 символів",
              },
              maxLength: {
                value: 15,
                message: "Імʼя повинно бути не більше 15 символів",
              },
            })}
          />
        </label>
        {errors.name && (
          <p className="errorMess">{errors.name && errors.name.message}</p>
        )}

        <label>
          <FaPhone />
          <input
            type="text"
            placeholder="Номер телефону"
            {...register("phone", {
              required: "Введіть номер телефону",
              pattern: {
                value: /^\+?[0-9]+$/,
                message: "Номер телефону не повинент містити букви і пропуски",
              },
              maxLength: {
                value: 20,
                message: "Номер телефону повинен бути не більше 20 символів",
              },
              minLength: {
                value: 10,
                message: "Номер телефону повинен бути не менше 10 символів",
              },
            })}
          />
        </label>
        {errors.phone && (
          <p className="errorMess">{errors.phone && errors.phone.message}</p>
        )}

        <label>
          <HiMapPin />
          <input
            type="text"
            placeholder="Звідки"
            {...register("from", {
              required: "Введіть місце відправлення",
              minLength: {
                value: 2,
                message: "Місце відправлення повинно бути не менше 2 символів",
              },
              maxLength: {
                value: 20,
                message:
                  "Місце відправлення повинно бути не більше 20 символів",
              },
            })}
          />
        </label>
        {errors.from && (
          <p className="errorMess">{errors.from && errors.from.message}</p>
        )}

        <label>
          <HiMapPin />
          <input
            type="text"
            placeholder="Куди"
            {...register("to", {
              required: "Введіть місце прибуття",
              minLength: {
                value: 2,
                message: "Місце прибуття повинно бути не менше 2 символів",
              },
              maxLength: {
                value: 20,
                message: "Місце прибуття повинно бути не більше 20 символів",
              },
            })}
          />
        </label>
        {errors.to && (
          <p className="errorMess">{errors.to && errors.to.message}</p>
        )}

        <label className={styles.dateLabel}>
          <FaCalendarCheck />
          <input type="text" placeholder="Дата" value={selectedDate} />
          <input
            className={styles.hidden}
            type="date"
            ref={dateInputRef}
            {...register("date", {
              required: "Введіть дату",
              setValueAs: (value) => {
                setSelectedDate(value);
                return value;
              },
            })}
          />
        </label>
        {errors.date && (
          <p className="errorMess">{errors.date && errors.date.message}</p>
        )}

        <textarea
          placeholder="Додаткова інформація"
          ref={textareaRef}
          onChange={(e) => {
            autoHeight();
          }}
          {...register("additionalInfo", {
            maxLength: {
              value: 200,
              message: "Повідомлення повинно бути не більше 200 символів",
            },
          })}
        ></textarea>
        {errors.additionalInfo && (
          <p className="errorMess">
            {errors.additionalInfo && errors.additionalInfo.message}
          </p>
        )}

        <button type="submit" disabled={!isValid}>
          <Button>
            Забронювати поїздку
            <BsChevronRight />
          </Button>
        </button>
      </form>
    </div>
  );
};

export default OrderRidePopup;
