import React, { useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import styles from "./mainContent.module.scss";
import Banner from "./Banner";
import Reservation from "./Reservation";
import About from "./About";
import CarPark from "./CarPark";
import Reviews from "./Reviews";
import Answers from "./Answers";
import TravelRoutes from "./TravelRoutes";
import FindOutThePricePopup from "./FindOutThePricePopup";
import OrderRidePopup from "./OrderRidePopup";

const MainContent = () => {
  const [isShowFindOutThePricePopup, setIsShowFindOutThePricePopup] =
    useState(false);
  const [isShowOrderRidePopup, setIsShowOrderRidePopup] = useState(false);

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (isShowFindOutThePricePopup || isShowOrderRidePopup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isShowFindOutThePricePopup]);

  useEffect(() => {
    if (isShowFindOutThePricePopup || isShowOrderRidePopup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isShowOrderRidePopup]);

  return (
    <div className={` mainContent ${styles.mainContent}`}>
      <Banner setIsShowFindOutThePricePopup={setIsShowFindOutThePricePopup} />
      <TravelRoutes
        setIsShowFindOutThePricePopup={setIsShowFindOutThePricePopup}
      />
      <Reservation setIsShowOrderRidePopup={setIsShowOrderRidePopup} />
      <About />
      <CarPark />
      <Reviews />
      <Answers />
      {isShowFindOutThePricePopup && (
        <FindOutThePricePopup
          setIsShowFindOutThePricePopup={setIsShowFindOutThePricePopup}
          setSuccess={setSuccess}
        />
      )}
      {isShowOrderRidePopup && (
        <OrderRidePopup
          setIsShowOrderRidePopup={setIsShowOrderRidePopup}
          setSuccess={setSuccess}
        />
      )}
      {success && (
        <div className={styles.success}>
          <FaCheckCircle />
        </div>
      )}
    </div>
  );
};

export default MainContent;
